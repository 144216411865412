<template>
  <section class="speakers mobile">
    <div class="speakers__content container">
      <h1 class="speakers__title">Featured Speakers</h1>
      <div class="speakers__cards">
        <div
          class="speakers__cards--single"
          v-for="speaker in speakers"
          :key="speaker.id"
        >
          <template v-if="speaker.bio">
            <button @click="toggleModal(speaker.id)" class="speaker-btn">
              <img :src="getImagePath(speaker.image)" :alt="speaker.name" />
              <div class="speaker-container">
                <h2>{{ speaker.name }}</h2>
                <h4 v-html="speaker.title"></h4>
              </div>
            </button>
          </template>
          <template v-else-if="speaker.website">
            <a
              :href="speaker.website"
              target="_blank"
              rel="noopener noreferrer"
              class="speaker-btn"
            >
              <img :src="getImagePath(speaker.image)" :alt="speaker.name" />
              <div class="speaker-container">
                <h2>{{ speaker.name }}</h2>
                <h4 v-html="speaker.title"></h4>
              </div>
            </a>
          </template>
          <template v-else>
            <div class="speaker-btn">
              <img :src="getImagePath(speaker.image)" :alt="speaker.name" />
              <div class="speaker-container">
                <h2>{{ speaker.name }}</h2>
                <h4 v-html="speaker.title"></h4>
              </div>
            </div>
          </template>
          <transition name="fade" appear>
            <div
              class="modal-overlay"
              v-if="speaker.bio && modals[speaker.id]"
              @click="toggleModal(speaker.id)"
            ></div>
          </transition>
          <transition name="pop" appear>
            <div
              class="modal"
              role="dialog"
              v-if="speaker.bio && modals[speaker.id]"
            >
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img :src="getImagePath(speaker.image)" :alt="speaker.name" />
                </div>
                <div class="speaker-info">
                  <h2>{{ speaker.name }}</h2>
                  <h3 v-if="speaker.title" v-html="speaker.title"></h3>
                  <p class="description" v-html="speaker.bio"></p>
                </div>
              </div>
              <button @click="toggleModal(speaker.id)" class="close">⨉</button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import speakersData from "./json/speakers.json";

export default {
  name: "Speakers",
  setup() {
    const speakers = ref(speakersData);
    const modals = ref({});

    // Only initialize modals for speakers with a bio.
    speakers.value.forEach((speaker) => {
      if (speaker.bio) {
        modals.value[speaker.id] = false;
      }
    });

    function toggleModal(id) {
      modals.value[id] = !modals.value[id];
    }

    function getImagePath(imagePath) {
      return require(`@/assets/speakers/${imagePath}`);
    }

    return { speakers, modals, toggleModal, getImagePath };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/speakers.scss";
</style>

<style scoped>
:deep(.description > a) {
  color: #fff;
  font-weight: 400;
}
</style>
