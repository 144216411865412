<template>
  <section class="hero denver">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            DNA HOUSE, Denver at <a href="https://maps.app.goo.gl/PoJiqDgHtJsGUPiP6" target="_blank">Venice Ristorante</a>
          </h1>
          <h2 class="hero__date">
            February 27th, 2025
          </h2>
          <a href="http://lu.ma/dnafund" target="_blank">
            <button class="hero__btn">
              Register Now
            </button>
          </a>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "@/styles/components/hero.scss";

  .hero.denver {
    padding: 100px 0 20px;
    min-height: 500px;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 85%) 0%, rgb(0 0 0 /40%)), url('~@/assets/general/venice.jpg');
  }
  </style>