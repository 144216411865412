<template>
  <header class="main-menu">
    <div class="container">
      <section class="main-menu__logo">
        <a href="/" class="logo">
          <img src="@/assets/dna-house.png">
        </a>
      </section>
    </div>
  </header>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/menu.scss';
</style>

<script>

export default {
  name: 'main-menu',
};
</script>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

  .main-menu {
    .formcta {
      &__btn {
        padding: 13px 20px;
        @include between ($breakpoint_lg, $breakpoint_xl) {
          padding: 10px 12px;
        }
        @include upto (650px) {
          margin: 0;
          padding: 8px 40px;
        }
      }
    }
  }
</style>