<template>
  <section class="sponsors general">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          Presenting Companies
      </h1>
      <div class="sponsors__logos">
        <template v-for="partner in partners" :key="partner.name">
          <a v-if="partner.url" :href="partner.url" target="_blank">
            <div
              class="sponsors__item single smaller"
            >
              <div class="sponsors__logo-container nobg">
                <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
              </div>
            </div>
          </a>
          <div
            v-else
            class="sponsors__item single smaller"
          >
            <div class="sponsors__logo-container nobg">
              <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const partners = ref([
      { logo: require('@/assets/korea/sponsors/burrito.png'), class: 'full-logo', name: 'Burrito Wallet', url: 'https://www.burritowallet.com/en' },
      { logo: require('@/assets/logos/swissfortress.png'), class: 'full-logo', name: 'Swiss Fortress', url: 'https://www.swissfortress.com/' },
      { logo: require('@/assets/korea/sponsors/libera.png'), class: 'logo libera', name: 'Libera Global AI', url: 'https://liberaglobal.ai/' },
      { logo: require('@/assets/logos/pentagon.png'), class: 'full-logo', name: 'Pentagon Games', url: 'https://pentagon.games/' },
      { logo: require('@/assets/singapore/sponsors/bos.png'), class: 'logo', name: 'BitcoinOS', url: 'https://www.bitcoinos.build/' },
      { logo: require('@/assets/logos/zkalyx.png'), class: 'full-logo', name: 'Zkalyx', url: 'https://www.zkalyx.com/' },
      { logo: require('@/assets/nashville/sponsors/chintai.png'), class: 'full-logo', name: 'Chintai', url: 'https://chintai.io/' },
      { logo: require('@/assets/korea/sponsors/wow.webp'), class: 'logo', name: 'WOW!?', url: 'https://wow.ai/' }
    ]);
    return {
      partners
    };
  },
};
</script>