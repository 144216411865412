<template>
  <section id="schedule">
    <dayone />
  </section>
</template>

<script>
import dayone from '@/components/denver/agenda/dayone.vue';

export default {
  name: 'Agenda Hong Kong',
  components: {
    dayone
  },
};
</script>

<style lang="scss">
  #schedule {
    padding: 20px 0 60px !important;
  }
</style>