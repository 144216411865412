<template>
  <main class="denver" id="schedule">
    <Menu />
    <hero />
    <sponsors />
    <agenda />
    <speakers />
    <presenters />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/denver/menu.vue';
import hero from '@/components/denver/hero.vue';
import agenda from '@/components/denver/agenda.vue';
import sponsors from '@/components/denver/sponsors.vue';
import speakers from '@/components/denver/speakers.vue';
import presenters from '@/components/denver/presenters.vue';

export default {
  name: 'Home',
  components: {
    Menu,
    hero,
    agenda,
    sponsors,
    presenters,
    speakers
  },
};
</script>

<style lang="scss">
  .hongkong {
    &#schedule {
      padding: 30px 0;
    }
  }
</style>