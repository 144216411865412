import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Agenda from '../views/Agenda.vue'
import Hongkong from '../views/onepager/hongkong.vue'
import Denver from '../views/onepager/denver.vue'
import SingaporeHome from '../views/singapore/Home.vue'
import SingaporeSponsor from '../views/singapore/Sponsor.vue'
import SingaporeEntertainment from '../views/singapore/Entertainment.vue'
import SingaporeSchedule from '../views/singapore/Schedule.vue'
import KoreaHome from '../views/korea/Home.vue'
import KoreaSponsor from '../views/korea/Sponsor.vue'
import KoreaEntertainment from '../views/korea/Entertainment.vue'
import KoreaSchedule from '../views/korea/Schedule.vue'
import NashvilleHome from '../views/nashville/Home.vue'
import NashvilleSponsor from '../views/nashville/Sponsor.vue'
import NashvilleEntertainment from '../views/nashville/Entertainment.vue'
import NashvilleSchedule from '../views/nashville/Schedule.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/denver',
    name: 'Denver',
    component: Denver
  },
  {
    path: '/hongkong',
    name: 'Hongkong',
    component: Hongkong
  },
  {
    path: '/luma',
    name: 'Agenda',
    component: Agenda
  },
  {
    path: '/singapore',
    name: 'Singapore Home',
    component: SingaporeHome
  },
  {
    path: '/singapore/sponsorships',
    name: 'Singapore Sponsorships',
    component: SingaporeSponsor
  },
  {
    path: '/singapore/entertainment',
    name: 'Singapore Entertainment',
    component: SingaporeEntertainment
  },
  {
    path: '/singapore/schedule',
    name: 'singapore Schedule',
    component: SingaporeSchedule
  },
  {
    path: '/korea',
    name: 'korea Home',
    component: KoreaHome
  },
  {
    path: '/korea/sponsorships',
    name: 'korea Sponsorships',
    component: KoreaSponsor
  },
  {
    path: '/korea/entertainment',
    name: 'korea Entertainment',
    component: KoreaEntertainment
  },
  {
    path: '/korea/schedule',
    name: 'korea Schedule',
    component: KoreaSchedule
  },
  {
    path: '/nashville',
    name: 'Nashville Home',
    component: NashvilleHome
  },
  {
    path: '/nashville/sponsorships',
    name: 'Nashville Sponsorships',
    component: NashvilleSponsor
  },
  {
    path: '/nashville/entertainment',
    name: 'Nashville Entertainment',
    component: NashvilleEntertainment
  },
  {
    path: '/nashville/schedule',
    name: 'Nashville Schedule',
    component: NashvilleSchedule
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
