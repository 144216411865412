<template>
  <section class="sponsors general">
    <div class="sponsors__content">
      <div class="sponsors__logos">
        <template v-for="partner in partners" :key="partner.name">
          <a v-if="partner.url" :href="partner.url" target="_blank">
            <div
              class="sponsors__item single smaller half-width"
            >
              <div class="sponsors__logo-container nobg">
                <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
              </div>
            </div>
          </a>
          <div
            v-else
            class="sponsors__item single smaller half-width"
          >
            <div class="sponsors__logo-container nobg">
              <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';

.sponsors.general {
  padding-top:20px;
  padding-bottom: 0;
}
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const partners = ref([
      { logo: require('@/assets/logos/zeebu.png'), class: 'logo', name: 'Zeebu', url: 'http://zeebu.com/' },
      { logo: require('@/assets/dna-white.png'), class: 'logo', name: 'DNA', url: 'https://dna.fund/' }
    ]);
    return {
      partners
    };
  },
};
</script>