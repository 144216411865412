<template>
  <section class="hero hongkong">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            DNA HOUSE, Hong Kong at Popinjays
          </h1>
          <h2 class="hero__date">
            February 18th - 19th, 2025
          </h2>
          <a href="http://lu.ma/dnafund" target="_blank">
            <button class="hero__btn">
              Register Now
            </button>
          </a>
          <a href="https://www.google.com/maps/search/?api=1&query=31%20Bukit%20Pasoh%20Rd&query_place_id=ChIJhwLe8m0Z2jERk8HAd9SzPy0" target="_blank">
            <img src="@/assets/logos/popinjays.png" class="venue-logo" alt="Popinjays Logo">
          </a>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "@/styles/components/hero.scss";

  .hero.hongkong {
    padding: 100px 0 20px;
    min-height: 500px;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 90%) 0%, rgb(0 0 0 /65%)), url('~@/assets/general/popinjays.jpg');
  }
  </style>