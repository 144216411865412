<template>
  <section class="sponsors general">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          Presenting Companies
      </h1>
      <div class="sponsors__logos">
        <template v-for="partner in partners" :key="partner.name">
          <a v-if="partner.url" :href="partner.url" target="_blank">
            <div
              class="sponsors__item single smaller"
            >
              <div class="sponsors__logo-container nobg">
                <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
              </div>
            </div>
          </a>
          <div
            v-else
            class="sponsors__item single smaller"
          >
            <div class="sponsors__logo-container nobg">
              <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const partners = ref([
      { logo: require('@/assets/nashville/sponsors/hemi.png'), class: 'full-logo', name: 'Hemi', url: 'https://hemi.xyz/' },
      { logo: require('@/assets/nashville/sponsors/quai.png'), class: 'full-logo', name: 'Quai Network', url: 'https://qu.ai/' },
      { logo: require('@/assets/logos/pentagon.png'), class: 'full-logo', name: 'Pentagon Games', url: 'https://pentagon.games/' },
      { logo: require('@/assets/nashville/sponsors/kredete.png'), class: 'full-logo', name: 'Kredete', url: 'https://www.kredete.com/' },
      { logo: require('@/assets/korea/sponsors/wow.webp'), class: 'full-logo', name: 'WOW!?', url: 'https://wow.ai/' },
      { logo: require('@/assets/singapore/sponsors/bos.png'), class: 'logo', name: 'BitcoinOS', url: 'https://www.bitcoinos.build/' },
      { logo: require('@/assets/logos/zkalyx.png'), class: 'full-logo', name: 'Zkalyx', url: 'https://www.zkalyx.com/' },
      { logo: require('@/assets/logos/matterfi.svg'), class: 'full-logo', name: 'MatterFi', url: 'https://www.matterfi.com/' },
      { logo: require('@/assets/logos/monoquant.svg'), class: 'logo', name: 'Monoquant', url: 'https://www.monoquant.com/' },
      { logo: require('@/assets/logos/infinity.png'), class: 'full-logo', name: 'Infinity Ground', url: 'https://www.infinityg.ai/' }
    ]);
    return {
      partners
    };
  },
};
</script>