<template>
  <section class="agenda tuesday">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <div>
            <h1 class="date__title">
              Thursday, February 27th
            </h1>
          </div>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated, 'iphone-btn': isIphone }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="14px" height="9px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,7.8l6.6-6.6C15.8,1.1,16,1,16.1,0.9c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3
              s0.2,0.3,0.3,0.4C18,1.8,18,2,18,2.2s0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4l-7.5,7.5C9.8,10.8,9.5,11,9.2,11
              c-0.4,0-0.7-0.1-0.9-0.4L0.8,3.1C0.7,3,0.6,2.8,0.5,2.7C0.3,2.5,0.2,2.4,0.2,2.2c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4
              S0.9,1.1,1,1c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0,0.5,0.1C2.3,1,2.4,1.1,2.5,1.2L9.1,7.8z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <div class="cards__container">
            <div class="cards__single grayblue">
              <p class="cards__title">
                Doors Open
              </p>
              <div class="cards__description">
                <p>11:00 AM</p>
              </div>
            </div>
            <br class="linebrk">
            <div v-for="(speaker, index) in firstSet" :key="index" class="cards__single cerulean" :class="speaker.class">
              <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
              <div v-if="speaker.logoClass" :class="'logo-img ' + speaker.logoClass"></div>
              <div v-if="speaker.imgClass" class="circle"></div>
              <div class="cards__speaker">
                <p class="cards__name">{{ speaker.name }}</p>
                <p class="cards__spktitle" v-html="speaker.position"></p>
                <p v-if="speaker.title" class="cards__spktitle" v-html="speaker.title"></p>
              </div>
              <div class="cards__description">
                <p class="time">{{ speaker.time }}</p>
                <p v-if="speaker.location" class="location">{{ speaker.location }}</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div class="cards__single grayblue">
              <p class="cards__title">
                VIP Lunch
              </p>
              <div class="cards__description">
                <p>1:00 PM - 2:00 PM</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div v-for="(speaker, index) in secondSet" :key="index" class="cards__single cerulean" :class="speaker.class">
              <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
              <div v-if="speaker.logoClass" :class="'logo-img ' + speaker.logoClass"></div>
              <div v-if="speaker.imgClass" class="circle"></div>
              <div class="cards__speaker">
                <p class="cards__name">{{ speaker.name }}</p>
                <p class="cards__spktitle" v-html="speaker.position"></p>
                <p v-if="speaker.title" class="cards__spktitle" v-html="speaker.title"></p>
              </div>
              <div class="cards__description">
                <p class="time">{{ speaker.time }}</p>
                <p v-if="speaker.location" class="location">{{ speaker.location }}</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div class="cards__single cocktail">
              <p class="cards__title">
                Cocktail Hour
              </p>
              <div class="cards__description">
                <p>5:30 PM - 7:30 PM</p>
              </div>
            </div>
            <div class="cards__single dinner">
              <p class="cards__title">
                Decentralized Supper Club
              </p>
              <div class="cards__description">
                <p>7:30 PM - 9:30 PM</p>
              </div>
            </div>
            <div class="cards__single party">
              <p class="cards__title">
                Legends Never Die Party
              </p>
              <div class="cards__description">
                <p>9:30 PM - 1:00 AM</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/agenda.scss';
</style>

<script>
export default {
  name: 'Day One',
  data() {
    return {
      seccionVisible: true,
      isRotated: false,
      isIphone: false,
      firstSet: [
        {
          imgClass: 'miglino',
          name: 'Chris Miglino',
          position: 'CEO, Co-Founder - DNA',
          title: 'Welcome Keynote',
          time: '11:20 AM - 11:40 AM',
        },
        {
          logoClass: 'matterfi',
          name: 'MatterFi',
          title: 'Company Presentation',
          time: '11:40 AM - 12:00 PM',
          class: 'logo'
        },
        {
          logoClass: 'pentagon',
          name: 'Pentagon Games',
          title: 'Company Presentation',
          time: '12:00 PM - 12:30 PM',
          class: 'logo'
        },
        {
          logoClass: 'monoquant',
          name: 'Monoquant',
          title: 'Company Presentation',
          time: '12:30 PM - 1:00 PM',
          class: 'logo'
        }
      ],
      secondSet: [
        {
          name: 'Panel: AI & Web3 - The Convergence of Intelligence and Decentralization',
          title: 'Moderator: Michal “Mehow” Pospieszalski, Matterfi<br>Rakshak Talwar<br>Michael Gord, GDA Capital<br>Tim Lewis, tea<br>Lani, Infinity Ground<br>David Johnson, Morpheus',
          time: '2:00 PM - 2:30 PM',
          class: 'noheight panel'
        },
        {
          logoClass: 'bos',
          name: 'BitcoinOS',
          title: 'Company Presentation',
          time: '2:30 PM - 2:50 PM',
          class: 'logo'
        },
        {
          logoClass: 'kredete',
          name: 'Kredete',
          title: 'Company Presentation',
          time: '2:50 PM - 3:10 PM',
          class: 'logo'
        },
        {
          name: 'The Future of Finance - Tokenization & Real-World Assets',
          title: 'Moderator: Chris Miglino<br>Luke X, Plume<br>Raj Brahmbatt, Zeebu<br>Fahmi Syed, Midnight<br>Kevin Cafeo, Valuit',
          time: '3:10 PM - 3:40 PM',
          class: 'noheight panel'
        },
        {
          logoClass: 'quai',
          name: 'Quai Network',
          title: 'Company Presentation',
          time: '3:40 PM - 4:00 PM',
          class: 'logo'
        },
        {
          name: 'The Future of Digital Identity & Sovereignty',
          title: 'Trevor Koverko, Sapien<br>Mauricio Magaldi, Midnight',
          time: '4:00 PM - 4:30 PM',
          class: 'noheight panel'
        },
        { 
          logoClass: 'zkalyx',
          name: 'Zkalyx',
          title: 'Company Presentation',
          time: '4:30 PM - 4:50 PM',
          class: 'logo'
        },
        {
          logoClass: 'dna',
          name: 'DNA',
          title: 'Company Presentation',
          time: '4:50 PM - 5:10 PM',
          class: 'logo'
        },
        {
          logoClass: 'zeebu',
          name: 'Zeebu',
          title: 'Company Presentation',
          time: '5:10 PM - 5:30 PM',
          class: 'logo'
        }
      ]
    }
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>
