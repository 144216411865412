<template>
  <section id="schedule">
    <dayone />
    <daytwo />
  </section>
</template>

<script>
import dayone from '@/components/hongkong/2025/agenda/dayone.vue';
import daytwo from '@/components/hongkong/2025/agenda/daytwo.vue';

export default {
  name: 'Agenda Hong Kong',
  components: {
    dayone,
    daytwo
  },
};
</script>

<style lang="scss">
  #schedule {
    padding: 20px 0 60px !important;
  }
</style>