<template>
  <section class="agenda tuesday">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <div>
            <h1 class="date__title">
              Wednesday, February 19th
            </h1>
          </div>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated, 'iphone-btn': isIphone }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="14px" height="9px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,7.8l6.6-6.6C15.8,1.1,16,1,16.1,0.9c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3
              s0.2,0.3,0.3,0.4C18,1.8,18,2,18,2.2s0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4l-7.5,7.5C9.8,10.8,9.5,11,9.2,11
              c-0.4,0-0.7-0.1-0.9-0.4L0.8,3.1C0.7,3,0.6,2.8,0.5,2.7C0.3,2.5,0.2,2.4,0.2,2.2c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4
              S0.9,1.1,1,1c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0,0.5,0.1C2.3,1,2.4,1.1,2.5,1.2L9.1,7.8z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <div class="cards__container">
            <div class="cards__single grayblue">
              <p class="cards__title">
                Doors Open
              </p>
              <div class="cards__description">
                <p>11:00 AM</p>
              </div>
            </div>
            <br class="linebrk">
            <div v-for="(speaker, index) in firstSet" :key="index" class="cards__single cerulean" :class="speaker.class">
              <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
              <div v-if="speaker.logoClass" :class="'logo-img ' + speaker.logoClass"></div>
              <div v-if="speaker.imgClass" class="circle"></div>
              <div class="cards__speaker">
                <p class="cards__name">{{ speaker.name }}</p>
                <p class="cards__spktitle" v-html="speaker.position"></p>
                <p v-if="speaker.title" class="cards__spktitle" v-html="speaker.title"></p>
              </div>
              <div class="cards__description">
                <p class="time">{{ speaker.time }}</p>
                <p v-if="speaker.location" class="location">{{ speaker.location }}</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div class="cards__single grayblue">
              <p class="cards__title">
                Lunch
              </p>
              <div class="cards__description">
                <p>12:30 PM - 1:30 PM</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div v-for="(speaker, index) in secondSet" :key="index" class="cards__single cerulean" :class="speaker.class">
              <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
              <div v-if="speaker.logoClass" :class="'logo-img ' + speaker.logoClass"></div>
              <div v-if="speaker.imgClass" class="circle"></div>
              <div class="cards__speaker">
                <p class="cards__name">{{ speaker.name }}</p>
                <p class="cards__spktitle" v-html="speaker.position"></p>
                <p v-if="speaker.title" class="cards__spktitle" v-html="speaker.title"></p>
              </div>
              <div class="cards__description">
                <p class="time">{{ speaker.time }}</p>
                <p v-if="speaker.location" class="location">{{ speaker.location }}</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div class="cards__single cocktail">
              <p class="cards__title">
                Cocktail Hour
              </p>
              <div class="cards__description">
                <p>5:30 PM - 7:30 PM</p>
              </div>
            </div>
            <div class="cards__single dinner">
              <p class="cards__title">
                Dinner
              </p>
              <div class="cards__description">
                <p>7:30 PM - 9:30 PM</p>
              </div>
            </div>
            <div class="cards__single party">
              <p class="cards__title">
                Closing Party
              </p>
              <div class="cards__description">
                <p>9:30 PM - 1:00 AM</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/agenda.scss';
</style>

<script>
export default {
  name: 'Day Two',
  data() {
    return {
      seccionVisible: true,
      isRotated: false,
      isIphone: false,
      firstSet: [
      {
        imgClass: 'miglino',
        name: 'Chris Miglino',
        position: 'CEO, Co-Founder - DNA',
        title: 'Kickoff Keynote',
        time: '11:30 AM - 12:30 PM',
      }],
      secondSet: [
      {
        logoClass: 'swiss',
        name: 'Swiss Fortress',
        title: 'Company Presentation',
        time: '1:30 PM - 2:00 PM',
        class: 'logo'
      },
      {
        logoClass: 'bos',
        name: 'BitcoinOS',
        title: 'Company Presentation',
        time: '2:00 PM - 2:30 PM',
        class: 'logo'
      },
      {
        name: 'Panel: De-Sci, AI + Web3 Community Driven Innovation Panel',
        title: 'Moderator: Max Song<br>YoSean Wang - DESCI SINO Co-Founder<br>Logan Golema - AI16Z Co-Founder<br>Jae - BIO Protocol APAC Ecosystem Lead<br>Gabe Yang - Deputy CEO @ARC Community',
        time: '2:30 PM - 3:15 PM',
        class: 'noheight panel'
      },
      { 
        logoClass: 'chintai',
        name: 'Chintai',
        title: 'Company Presentation',
        time: '3:15 PM - 3:45 PM',
        class: 'logo'
      },
      {
        logoClass: 'zeebu',
        name: 'Zeebu',
        title: 'Company Presentation',
        time: '3:45 PM - 4:15 PM',
        class: 'logo'
      },
      {
        logoClass: 'burrito',
        name: 'Burrito Wallet',
        title: 'Company Presentation',
        time: '4:15 PM - 4:45 PM',
        class: 'logo'
      },
      {
        name: 'Panel: Future of Network States',
        title: 'Moderator: Max Song<br>Amber - Zuzalu Core Contributor<br>Luc - RAKDAO CCO, ex Fidelity<br>Jeff - 6529, ex CFTC<br>Tandin - Bhutan GMC<br>Art - Vana Co-Founder',
        time: '4:45 PM - 5:30 PM',
        class: 'noheight panel'
      }]
    }
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>
